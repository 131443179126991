import api from '@/services/api.js'
import Vue from 'vue'
import router from '../../router'

const state = {
    coloringPages: [],
    files: {},
    categories: [],
    sortings: [],
    selectedSorting: {},
    search: '',
    page: 0,
    total: 0,

    loadingPages: false,
    loadingCategories: false
}

const mutations = {
  setColoringPages(state, pages) {
    state.coloringPages = pages
  },

  setFiles(state, data) {
    Vue.set(state.files, data.id, data.page)
  },
  
  setCategories(state, categories) {
    state.categories = categories
  },

  setSortings(state, sortings) {
    state.sortings = sortings
  },

  setSelectedSorting(state, sorting) {
    state.selectedSorting = sorting
  },

  setSearch(state, search) {
    state.search = search
  },

  setPage(state, page) {
    state.page = page
  },

  setTotal(state, total) {
      state.total = total
  },

  setLoadingPages(state, loading) {
    state.loadingPages = loading
  },

  setLoadingCategories(state, loading) {
    state.loadingCategories = loading
  }
}

const actions = {
  async setCachedFiles({commit}) {
    for (var i = 0; i < state.coloringPages.length; i++) {
      const page = state.coloringPages[i]
      if (!(page.id in state.files)) {
        const svg = await (await fetch(page.page_url)).text()
        const blob = new Blob([svg.toString()], {type: 'image/svg+xml'})
        page.url = URL.createObjectURL(blob)
        commit('setFiles', {id: page.unique_name, page: page})
      }
    }
  },

  async retrievePages({commit, dispatch}, addedPage) {
    commit('setLoadingPages', true)

    const page = addedPage ? state.page + 1 : 0
    commit('setPage', page)

    const response = await api.post('/filtered_coloring_pages.json', {
        category_name: router.currentRoute.params.category,
        search: state.search,
        size: 30,
        page: state.page,
        sorting: state.selectedSorting
    })

    if (response.data) {
        const pages = state.page > 0 ? state.coloringPages.concat(response.data.pages) : response.data.pages
        commit('setColoringPages', pages)
        commit('setTotal', response.data.total)
        dispatch('setCachedFiles')
    }

    commit('setLoadingPages', false)
  },

  async retrieveCategories({commit}) {
    commit('setLoadingCategories', true)

    const response = await api.get('/categories.json')
    
    if (response.data) commit('setCategories', response.data)

    commit('setLoadingCategories', false)
  },

  async retrieveSortings({commit}) {
    const response = await api.get('/sortings.json')
    
    if (response.data) {
        commit('setSortings', response.data)
        commit('setSelectedSorting', response.data[0])
    }
  },
  
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}