import pdf from 'vue-pdf'
import {mapState} from 'vuex'

export default {
    name: 'Overview',
    components: {
        pdf
    },
    data() {
        return {
            selectedPage: {},
        }
    },
    computed: {
        ...mapState({
            pages: state => state.pages.coloringPages,
            files: state => state.pages.files,
            loading: state => state.pages.loadingPages,
            totalPages: state => state.pages.total,
            search: state => state.pages.search,
            sortings: state => state.pages.sortings,
            selectedSorting: state => state.pages.selectedSorting
        }),
    },
    methods: {
        loadMore() {
            this.$store.dispatch('pages/retrievePages', true);
        },
        removeSearched() {
            this.$store.commit('pages/setSearch', '');
            this.$store.dispatch('pages/retrievePages', false);
        },        
        setSort(sorting) {
            this.$store.commit('pages/setSelectedSorting', sorting)
            this.$store.dispatch('pages/retrievePages', false);
        },
        showColoringPage(name) {
            this.$router.push({name: 'Detail', params: {page: name}})
        },
        getUrl(id) {
            return this.files[id].url
        }
    },
    async created() {
        this.$store.dispatch('pages/retrievePages', false);
        this.$store.dispatch('pages/retrieveSortings');
    },
}
