import {mapState} from 'vuex'

export default {
    name: 'Categories',
    data() {
        return {
            search: ''
        }
    },
    computed: {
        ...mapState({
            categories: state => state.pages.categories,
            loading: state => state.pages.loadingCategories
        }),
        selectedCategory() {
            return this.$route.params.category
        }
    },
    methods: {
        setSelectedCategory(title) {
            if (title != this.$route.params.category || this.$route.params.page) this.$router.push({name: 'Home', params: {category: title}})
            this.$store.dispatch('pages/retrievePages', false);
        },
        searchPages() {
            this.$store.commit('pages/setSearch', this.search);
            this.$store.dispatch('pages/retrievePages', false);
        },
    },
    async created() {
        this.$store.dispatch('pages/retrieveCategories');
    }     
}
