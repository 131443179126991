import Header from '@/components/Header/Header.vue'
import Advertisement from '@/components/Advertisement/Advertisement.vue'
import Overview from '@/components/Overview/Overview.vue'
import Categories from '@/components/Categories/Categories.vue'
import api from '@/services/api.js'
import {mapState} from 'vuex'
import print from 'print-js'

export default {
    name: 'Detail',
    components: {
        Header,
        Advertisement,
        Categories,
        Overview
    },
    computed: {
        ...mapState({
            files: state => state.pages.files,
        }),
        page() {
            return this.files[this.$route.params.page]
        }
    },
    methods: {
        printFile() {
            print({
                printable: this.page.url, 
                type: 'image',
                style: '@page { size: A4; margin: 0; }',  
                targetStyles: ['*']
            })
            api.post('/views.json', {
                coloring_page_id: this.page.id
            })
        },
    },
    created() {
        if (!this.page) this.$store.dispatch('pages/retrievePages', false);
    }
}
