import Header from '@/components/Header/Header.vue'
import Advertisement from '@/components/Advertisement/Advertisement.vue'
import Overview from '@/components/Overview/Overview.vue'
import Categories from '@/components/Categories/Categories.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Advertisement,
    Categories,
    Overview
  },
}
