<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
@import './assets/styles/variables.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: var(--pink);
}
</style>

<script>
export default {
  mounted() {
    let script = document.createElement('script')
    script.async = true
    script.setAttribute('data-ad-client', 'ca-pub-9957964751136587')
    script.setAttribute('src', 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js')

    document.head.appendChild(script)
  }
}
</script>
