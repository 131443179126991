export default {
    async get(url) {
        try {
            const response = await (await fetch(this.getUrl(url))).json()
            return response
        } catch (error) {
            return this.throwError(error)
        }
    },

    async post(url, body) {
        try {
            const response = await (await fetch(this.getUrl(url), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })).json()
            return response
        } catch (error) {
            return this.throwError(error)
        }
    },

    async postFormData(url, body) {
        try {
            const response = await (await fetch(this.getUrl(url), {
                method: 'POST',
                body: body
            })).json()
            return response
        } catch (error) {
            return this.throwError(error)
        }
    },

    getUrl(path) {
        const base = process.env.NODE_ENV == 'development' ? 'http://localhost:3000' : 'https://api.kleurvel.nl'
        return base + path
    },

    throwError(error) {
        console.log(error)
        return error
    }
}

/**
 * Tests
 * async get() {
        const response = await apiService.saveGet('employees')
        console.log('get', response)
    },
    async post() {
        const response = await apiService.savePost('employees', {name: 'name'})
        console.log('post', response)
    },
    async update() {
        const response = await apiService.saveUpdate('employees/122', {name: 'name-update'})
        console.log('update', response)
    },
    async destroy() {
        const response = await apiService.saveDestroy('employees/3')
        console.log('destroy', response)
    },
    async show() {
        const response = await apiService.saveGet('employees/6')
        console.log(response)
    },
    async instance() {
        console.log(singularize(this.model))
        const response = await apiService.saveGet(`new-${singularize(this.model)}`)
        console.log(response)
    },
    async schema() {
        const response = await apiService.saveGet(`schema-${this.model}`)
        console.log(response)
    }
 */