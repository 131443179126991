import api from "../../services/api"

export default {
    name: 'Admin',
    data() {
        return {
            name: null,
            description: null,
            password: null,
            tags: [],
            categoryIds: [],
            categories: [],
            url: null,
            file: null,
            errors: []
        }
    },
    methods: {
        async convertToBase64() {
            var selectedFiles = document.getElementById("inputFile").files;
            //Check File is not Empty
            if (selectedFiles.length > 0) {
                this.url = URL.createObjectURL(selectedFiles[0])
                this.file = selectedFiles[0]
            }
        },
        checkForm() {
            if (this.name && this.description && this.file && this.password && (this.tags.length >= 3)) {
                return true
            }

            this.errors = []
            if (!this.name) {
                this.errors.push('Naam is verplicht')
            }

            if (!this.description) {
                this.errors.push('Vul een korte beschrijving in')
            }

            if (this.tags.length < 3) {
                this.errors.push('Vul minimaal 3 zoektermen in')
            }

            if (!this.password) {
                this.errors.push('Wachtwoord is verplicht')
            }

            if (!this.file) {
                this.errors.push('Upload een tekening als svg bestand')
            }
            return false
        },
        async saveColoringPage() {
            if (this.checkForm()) {
                const formData = new FormData;
                formData.append('file', this.file)
                formData.append('coloring_page[title]', this.name)
                formData.append('coloring_page[description]', this.description)
                formData.append('search_terms', JSON.stringify(this.tags))
                formData.append('password', this.password)
                formData.append('category_ids', JSON.stringify(this.categoryIds))
                formData.append('coloring_page[creator_id]', 1)
    
                try {
                    const response = await api.postFormData('/coloring_pages', formData)
                    if (response.data) {
                        this.$swal('Gelukt', 'Kleurvel is toegevoegd aan de kluis!', 'success')
                        this.cleanFields();
                    } else {
                        console.log(response)
                        this.$swal('Oeps', 'Er is een fout opgetreden', 'error')
                    }
                } catch(error) {
                    console.log(error)
                    this.$swal('Oeps', 'Er is een fout opgetreden', 'error')
                }
            }
        },
        cleanFields() {
            this.name = null,
            this.description = null,
            this.tags = [],
            this.categoryIds = [],
            this.base64 = null,
            this.file = null,
            document.getElementById('inputFile').value = "";
            this.errors = []
        },
        addTag (event) {
            event.preventDefault()
            var val = event.target.value.trim()
            if (val.length > 0) {
              this.tags.push(val.toLowerCase())
              event.target.value = ''
            }
        },
        removeTag (index) {
            this.tags.splice(index, 1)
        },
        removeLastTag(event) {
            if (event.target.value.length === 0) {
              this.removeTag(this.tags.length - 1)
            }
        }
    },
    async created() {
        this.categories = await (await api.get('/categories.json')).data
    }
}
